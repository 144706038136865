<template>
  <header
    v-if="currentRouteName != 'wait'"
    class="w-full flex justify-center pl-4 bg-[#273A60] border-b border-white relative z-40 h-32"
  >
    <div
      class="max-w-7xl w-full text-white text-base flex flex-wrap justify-between items-center p-4"
    >
      <div class="w-3/12 pt-1">
        <router-link to="/"
          ><img
            src="@/assets/images/logo2.png"
            class="!min-w-[200px] !max-w-[210px]"
        /></router-link>
      </div>
      <div
        class="w-9/12 uppercase pl-12 items-center flex justify-end md:justify-between pt-2"
      >
        <div class="w-full md:w-auto flex justify-end md:justify-start">
          <ul class="flex font-semibold gap-2 md:gap-4">
            <li>
              <a
                href="https://www.husqvarna.com/fr/"
                class="hover:text-blue-200"
                target="_blank"
                ><i class="fa-solid fa-house mr-1"></i>
                <span class="hidden md:inline-block">SITE Husqvarna</span>
              </a>
            </li>
            <li></li>
            <li v-if="currentRouteName != 'Status'">
              <router-link class="hover:text-blue-200" to="/contact"
                ><i class="fa-solid fa-envelope mr-1"></i>
                <span class="hidden md:inline-block"
                  >contactez-nous</span
                ></router-link
              >
            </li>
            <li v-if="currentRouteName === 'Status'">
              <router-link class="hover:text-blue-200" to="/contact"
                ><i class="fa-solid fa-envelope mr-1"></i>
                <span class="hidden md:inline-block"
                  >contactez-nous</span
                ></router-link
              >
            </li>
          </ul>
        </div>
        <div class="lg:flex hidden lg:text-2xl lg:gap-4 lg:pr-4">
          <a
            href="https://www.facebook.com/HusqvarnaFrance"
            class="hover:text-blue-200"
            target="_blank"
            ><i class="fa-brands fa-facebook"></i
          ></a>
          <a
            href="https://www.linkedin.com/company/husqvarnafrance/"
            class="hover:text-blue-200"
            target="_blank"
            ><i class="fa-brands fa-instagram"></i
          ></a>
          <a
            href="https://www.instagram.com/husqvarnafranceofficiel/"
            class="hover:text-blue-200"
            target="_blank"
            ><i class="fa-brands fa-linkedin"></i
          ></a>
          <a
            href="https://www.youtube.com/user/HusqvarnaFrance"
            class="hover:text-blue-200"
            target="_blank"
            ><i class="fa-brands fa-youtube"></i
          ></a>
        </div>
      </div>
    </div>
  </header>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      homelink: "/",
    };
  },
  watch: {
    currentRouteName() {
      const id = sessionStorage.getItem("_id");
      const uid = sessionStorage.getItem("uid");
      if (
        (id && uid && uid !== null && id !== null) ||
        (this.$route.params._id && this.$route.params.secu)
      ) {
        this.homelink = `/status/${id || this.$route.params._id}/${
          uid || this.$route.params.secu
        }`;
      }
    },
  },
  mounted() {},
  methods: {
    logout() {
      sessionStorage.clear();
      window.location.href = "/login";
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>
