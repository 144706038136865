import Vue from "vue";
import Router from "vue-router";

let consts = require("./config");

Vue.use(Router);

const router = new Router({
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "*",
      redirect: "/404",
    },
    {
      path: "/",
      name: "home",
      component: () => import("./views/Home.vue"),
    },

    {
      path: "/404",
      name: "404",
      component: () => import("./views/404.vue"),
    },
    {
      path: "/wait",
      name: "wait",
      component: () => import("./views/Wait.vue"),
    },
    {
      path: "/finish",
      name: "finish",
      component: () => import("./views/Finish.vue"),
    },
    {
      path: "/contact",
      name: "contact",
      component: () => import("./views/Contact.vue"),
    },
    {
      path: "/done",
      name: "done",
      component: () => import("./views/ContactDone.vue"),
    },
    {
      path: "/status/:_id/:_secu",
      name: "Status",
      component: () => import("./views/Status.vue"),
    },
  ],
});

export default router;

router.beforeEach(async (to, from, next) => {
  const today = new Date();
  const aprilFirst = new Date(today.getFullYear(), 3, 1);
  const host = window.location.host;
  console.log(host);
  if (
    host != "plateforme-atlantic-freshdays2024.pages.dev" &&
    host != "localhost:8080"
  ) {
    if (today < aprilFirst && to.name !== "wait") {
      return next("/wait");
    } else {
      return next();
    }
  } else {
    return next();
  }
});
